<template>
  <div class="page-accounting">
    <lead-center-navigation />
    <div class="container workflows-main-container">
      <div v-if="workflows.length">
        <h3>Existing Workflows</h3>
        <div class="workflow-cards">
          <div v-for="workflow in workflows" :key="workflow._id" class="workflow-card">
            <router-link :to="{ name: 'lead-center-workflow', params: { _id: workflow._id } }" class="workflow-link">
              <h4>{{ workflow.title }}</h4>
              <p><strong>Blocks count:</strong> {{ workflow.blocks.length }}</p>
              <p><strong>Platforms:</strong> {{ workflow.platforms.join(', ') }}</p>
              <p><strong>Application:</strong> {{ workflow.application }}</p>
              <p><strong>Type:</strong> {{ workflow.type }}</p>
            </router-link>
            <b-icon icon="pencil" variant="warning" class="edit-icon" @click="editWorkflow(workflow)" />
            <b-icon icon="trash" variant="danger" class="delete-icon" @click="deleteWorkflow(workflow._id)" />
          </div>
        </div>
      </div>
      <div v-else>
        <h3>Existing Workflows</h3>
        <p>Workflows not found</p>
      </div>

      <b-button variant="primary" @click="showAddForm">Add Workflow</b-button>

      <b-modal v-model="showForm" title="Add Workflow" hide-footer @hidden="resetForm">
        <b-form @submit.prevent="saveWorkflow">
          <b-form-group label="Title" label-for="workflow-title">
            <b-form-input
              id="workflow-title"
              v-model="newWorkflow.title"
              required
              class="border border-gray"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Platforms" label-for="workflow-platforms">
            <v-select
              id="workflow-platforms"
              v-model="newWorkflow.platforms"
              :options="platformOptions"
              multiple
              required
              :reduce="option => option.value"
              label="label"
            ></v-select>
          </b-form-group>

          <b-form-group label="Application" label-for="workflow-application">
            <v-select
              id="workflow-application"
              v-model="newWorkflow.application"
              :options="applicationOptions"
              required
              :reduce="option => option.value"
              label="label"
            ></v-select>
          </b-form-group>

          <b-form-group label="Type" label-for="workflow-type">
            <v-select
              id="workflow-type"
              v-model="newWorkflow.type"
              :options="typeOptions"
              required
              :reduce="option => option.value"
              label="label"
            ></v-select>
          </b-form-group>

          <b-alert
            v-if="errorMessage"
            show
            variant="danger"
            dismissible
            @dismissed="errorMessage = ''"
          >
            {{ errorMessage }}
          </b-alert>

          <b-button type="submit" variant="primary">{{ editMode ? 'Update' : 'Add' }}</b-button>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import LeadCenterNavigation from '../components/LeadCenterNavigation.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import MtApi from '../agGridV2/helpers/mt-api.helper'

export default {
  components: {
    LeadCenterNavigation,
    vSelect
  },
  data() {
    return {
      showForm: false,
      editMode: false,
      newWorkflow: {
        _id: null,
        title: '',
        platforms: [],
        application: '',
        type: ''
      },
      workflows: [],
      platformOptions: [],
      applicationOptions: [],
      typeOptions: [],
      errorMessage: ''
    }
  },
  async mounted() {
    this.setViewLoader(true)
    this.workflows = await MtApi.getWorkflows()
    const options = await MtApi.getWorkflowsOptions()
    this.platformOptions = options.platforms
    this.applicationOptions = options.applications
    this.typeOptions = options.types
    this.setViewLoader(false)
  },
  methods: {
    ...mapMutations(['setViewLoader']),
    showAddForm() {
      this.resetForm()
      this.showForm = true
    },
    async saveWorkflow() {
      const exists = this.workflows.some((workflow) => workflow.title === this.newWorkflow.title && workflow._id !== this.newWorkflow._id)
      if (exists) {
        this.errorMessage = 'Workflow with this title already exists. Please enter a different title.'
        return
      }

      if (this.editMode) {
        const index = this.workflows.findIndex((workflow) => workflow._id === this.newWorkflow._id)
        if (index !== -1) {
          await MtApi.createOrUpdateWorkflow(this.newWorkflow)
          this.workflows.splice(index, 1, { ...this.newWorkflow })
        }
      } else {
        const newWorkflow = { ...this.newWorkflow }
        delete newWorkflow._id
        const workflow = await MtApi.createOrUpdateWorkflow(newWorkflow)
        this.workflows.push(workflow)
      }

      this.resetForm()
      this.showForm = false
      this.errorMessage = ''
    },
    resetForm() {
      this.newWorkflow = {
        _id: null,
        title: '',
        platforms: [],
        application: '',
        type: ''
      }
      this.errorMessage = ''
      this.editMode = false
    },
    editWorkflow(workflow) {
      this.newWorkflow = { ...workflow }
      this.editMode = true
      this.showForm = true
    },
    async deleteWorkflow(_id) {
      this.$dialog
        .confirm(`<img style="width: 100%; margin-bottom: 20px;" src="/uncle_sam.webp" />Are you sure??? This will permanently delete workflow`, {
          html: true,
          cancelText: 'Close',
          okText: 'I\'m sure',
          clicksCount: 10,
          backdropClose: true,
          type: 'soft'
        })
        .then(async(dialog) => {
          await MtApi.deleteWorkflow(_id)
          this.workflows = this.workflows.filter((workflow) => workflow._id !== _id)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#workflow-title {
  padding: 0 7px 0 7px;
}
.border-gray {
  border-color: #d3d3d3 !important;
}

ul {
  list-style-type: none;
  padding: 0;
}

.workflow-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.workflow-card {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 15px;
  width: calc(33.333% - 10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.workflow-link {
  text-decoration: none;
  color: inherit;
}

.workflow-card h4 {
  margin-bottom: 10px;
}

.workflow-card p {
  margin: 5px 0;
}

.edit-icon, .delete-icon {
  position: absolute;
  top: 10px;
  cursor: pointer;
  font-size: 1.5em;
}

.edit-icon {
  right: 45px;
}

.delete-icon {
  right: 10px;
}
</style>
